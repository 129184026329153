import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import MinistryItem from "../ministries/ministry-item"
import Button from "../button-visit-us"
import Button2 from "../button-generic"
import { navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import TeamItem from "../team/team-item"

const LeadershipPage = (props) => {

  const {
    post,
    bannerHeight="408px",
    leadership,
    buttonBanner,
    toknowus,
    team
  } = props
  const { t } = useContext(LanguageContext)
  const breakpoints = useBreakpoint()

  const groupedTeam = useGroupedTeam(team);


  return(
    <div className="page-connect">
    <Banner
      post={post}
      height={bannerHeight}
      buttonBanner={buttonBanner}
      hasButton={true}
    />

    <section className="section-to-know-us p-t-80 p-b-80 medium-p-b-54 small-p-t-24 small-p-l-r-16 small-p-b-0">
        <div className="container p-0 toknowus">
        <h2 className="H2-Medium-C---bordeaux p-b-88 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0">
          {t("Coneix l'equip")}
        </h2>
        <SectionToKnowUs
          post={leadership}
          title={t("Coneix-nos")}
          imagePos="left"
          color="bordeaux"
        />
      </div>
    </section>


    <section className="section-connect p-t-80 p-b-88 background-beig medium-p-t-56 medium-p-b-20 small-p-t-24 small-p-l-r-16 small-p-b-0">
        <div className="container p-0 connect-with-us">
        <h2 className="H2-Medium-C---green-dark p-b-32 medium-m-b-16 small-m-b-16">
          {t("El nostre equip ministerial")}
        </h2>


        <div className="team-list m-w-1020 mx-auto">

            {Object.entries(groupedTeam)?.map(([key,teams], index) => {
              return(
                <div key={index}>
                    <div className="ministry-type text-center">{key === 'Pastoral' ? 'Equip Pastoral' : 'Equip Ministerial' }</div>
                    <div className="row m-b-65 gutter-0 small-m-b-0">
                    {teams && teams.length > 0 && teams.map((node, index) => {
                      return (
                        <TeamItem
                           key={node?.field_email}
                           title={node?.title}
                           email={node?.field_email}
                           role={node?.field_role}
                           type={node?.field_team_type}
                           date={node?.created}
                           image={node?.relationships?.field_image?.image_style_uri?.wide}
                           url="/lideratge-de-la-unida"
                           className={`col-md-3 team-list-item max-width-254 m-r-24 m-b-63 ${'team-list-item-' + index} ${teams.length - 1 === index ? 'last-item' : ''}` }
                        />
                      )
                    })}
                    </div>
                </div>
              )
            })}
        </div>
      </div>
    </section>


    <section className="section-to-know-us p-t-80 p-b-80 medium-p-b-54 small-p-t-24 small-p-l-r-16 small-p-b-64">
        <div className="container p-0 toknowus counsel">
        <h2 className="H2-Medium-C---bordeaux p-b-88 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0">
          {t("Consell de l’Església")}
        </h2>
        <SectionToKnowUs
          post={leadership}
          title={t("Consell de l’Església")}
          imagePos="right"
          color="bordeaux"
        />
      </div>
    </section>


    {leadership?.field_gls_title &&
      <section className="section-gls background-beig p-t-80 p-b-80 medium-p-b-54 small-p-t-24 small-p-l-r-16 small-p-b-64">
          <div className="container p-0 toknowus gls">
          <SectionToKnowUs
            post={leadership}
            title={leadership?.field_gls_title}
            imagePos="center"
            color="green-dark"
            image={leadership?.relationships?.field_gls_image?.image_style_uri?._653_432}
            body={leadership?.field_gls_body?.value}
            className="gls-container"
          />
        </div>
      </section>
    }

    </div>
  )
}

const SectionToKnowUs = (props) => {
  const {title, post, imagePos, className, color, image, body} = props
  const breakpoints = useBreakpoint()

if(imagePos === "left"){
  return(
    <div className="row img-left toknowus-item m-b-120 medium-m-b-114">
       <div className="col-12 col-sm-6 background-bordeux first-item max-width-683 image-wrapper">
          <img
            src={post?.relationships?.field_team_image?.image_style_uri?._653_432}
            alt={post?.field_team_title}
            className="m-r--24"
          />
       </div>
       <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
          <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_team_body?.value}} />
       </div>
    </div>
  )
  }

  if(imagePos === "right"){
    if(breakpoints.xs){
      return(
        <div className="row img-left toknowus-item m-b-120 medium-m-b-114">
           <div className="col-12 col-sm-6 background-bordeux first-item max-width-683 image-wrapper">
              <img
                src={post?.relationships?.field_council_image?.image_style_uri?._653_432}
                alt={post?.field_council_cta?.title}
                className="m-r--24"
              />
           </div>
           <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
              <div className="m-b-32-" dangerouslySetInnerHTML={{__html: post?.field_council_body?.value}} />

           </div>
        </div>
      )
    }else{
      return(
        <div className="row img-right toknowus-item pastoral-team">
           <div className="col-12 col-sm-6 p-l-169 p-r-82 m-r-3p medium-p-24-48 small-p-0">
              <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_council_body?.value}} />
              <div className="row">
                 <div className="container">
                    <div className="col-12 button-cta align-left">
                       <Button2
                          className="hide btn btn-default btn-outline-visit-us bordeaux"
                          title={post?.field_council_cta?.title}
                          onClick={() => navigate("/equip-pastoral")}
                          />
                    </div>
                 </div>
              </div>
           </div>
           <div className="col-12 col-sm-6 background-bordeux second-item max-width-683 small-p-0">
              <img
                src={post?.relationships?.field_council_image?.image_style_uri?._653_432}
                alt={post?.field_council_cta?.title}
                className="m-r--24"
              />
           </div>
        </div>
      )
    }
  }

  if(imagePos === "center"){
    return(
      <React.Fragment>
      <div className={`container ${className}`}>
        {title &&
          <div className="section-title">
            <h2 className={`H2-Medium-C---${color} p-b-32`} >
              {title}
            </h2>
          </div>
        }
        {body &&
          <div
            className="section-desc max-877-center-content text-center m-b-56"
            dangerouslySetInnerHTML={{__html: body}}
          />
        }

        {image &&
          <div className={`ministry-image m-b-16 small-m-b-10 text-center`}>
            <img
              src={image}
              alt={title}
              className="m-r--24-"
            />
          </div>
        }
      </div>
      </React.Fragment>
    )
  }
}



const SectionToVisitUs = (props) => {
  const {title, post, imagePos, pos, t} = props
  const breakpoints = useBreakpoint()

if(imagePos === "left" && pos === 1){
  return(
    <div className="row img-left visit-us-item m-b-120 medium-m-b-83">
       <div className="col-6 first-item max-width-683">
          <img
            src={post?.relationships?.field_visit_image_1?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-6 p-l-56 p-r-169 medium-p-l-r-25">
          <div className="m-b-32 m-t-88 medium-m-t-0 small-m-t-0-" dangerouslySetInnerHTML={{__html: post?.field_visit_body_1?.value}} />
       </div>
    </div>
  )
  }


    return(
      <div className="row  second m-b-66- medium-m-r-2 p-t-208 medium-p-t-0">
        <div className="col-md-6 second-first m-w-625 p-l-r-0- m-r-75- p-l-12 m-r-63 ">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
          </div>
        </div>
        <div className="col-md-6 second-second m-t--304 m-w-625 p-l-r-0 ">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_2?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
          </div>

          <div className="third p-t-80">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_4?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div className="m-b-8" dangerouslySetInnerHTML={{__html: post?.field_visit_body_4?.value}} />
            <div className="unida-social-media">
              <div dangerouslySetInnerHTML={{__html: post?.field_visit_social_media_body?.value}} />
            </div>
          </div>
          </div>
        </div>


      </div>
    )

}

const SectionToVisitUsMobile = (props) => {
  const {title, post, imagePos, pos, t} = props

if(imagePos === "left" && pos === 1){
  return(
    <div className="row img-left visit-us-item m-b-120 medium-m-b-83 first medium-p-l-r-24 small-m-b-20">
       <div className="col-12 col-sm-6 first-item max-width-683 small-m-b-8">
          <img
            src={post?.relationships?.field_visit_image_1?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-12 col-sm-6">
          <div className="m-b-32 m-t-88 medium-m-t-0 small-m-t-0 small-m-b-0" dangerouslySetInnerHTML={{__html: post?.field_visit_body_1?.value}} />
       </div>
    </div>
  )
  }


    return(
      <>
      <div className="row second small-m-b-20"> {/* first */}
        <div className="col-12 col-sm-12 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_3?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-12 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
        </div>
      </div>

      <div className="row third"> {/* second */}
        <div className="col-12 col-sm-6 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_2?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-6 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
        </div>
      </div>

      <div className="row forth"> {/* third */}

        <div className="col-12 col-sm-6 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_4?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_3?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-6 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div className="m-b-8" dangerouslySetInnerHTML={{__html: post?.field_visit_body_4?.value}} />
          <div className="unida-social-media">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_social_media_body?.value}} />
          </div>
        </div>

      </div>

      {/*
      <div className="second m-b-66 medium-m-r-2 p-t-208 medium-p-t-0">
        <div className="second-first row">
          <div className="col-6 ministry-image background-beig read-more m-b-42 medium-m-w-348">
            <img
              src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="col-6 ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
          </div>
        </div>
      </div>
      */}
      {/*
        <div className="row-">
        <div className="row second-second m-t--304 m-w-625 p-l-r-0 ">
          <div className="col-6 ministry-image background-beig read-more m-b-42 medium-m-w-348">
            <img
              src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_2?.title}
              className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="col-6 ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
          </div>
        </div>
        </div>

        */}


      </>
    )

}

const SectionConnectUs = (props) => {
  const {
    t,
    pos,
    imageFirst,
    titleFirst,
    descFirst,
    urlFirst,
    imageSecond,
    titleSecond,
    descSecond,
    urlSecond
  } = props

  const breakpoints = useBreakpoint()

  return(
    <div className={`row small-p-l-r-0 ${ pos === 1 ? 'm-b-80' : ''} medium-m-b-40 small-m-0 small-m-b-32`}>
      <div className={`${breakpoints.md ? 'col-sm-2' : 'col-sm-3'}`}></div>
      <div className={`${breakpoints.md ? 'col-sm-4' : 'col-sm-3'} col-6 ministry-item`}>
        <div>
          <div className="ministry-image background-white small-m-b-4">
            <img
              src={imageFirst}
              alt={titleFirst}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleFirst} </h2>
          <div className="ministry-desc">
            <div className="m-b-16" dangerouslySetInnerHTML={{__html: descFirst }} />
          </div>
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more bordeaux right"
             //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
        </div>
      </div>

      <div className={`${breakpoints.md ? 'col-sm-4' : 'col-sm-3'} col-6 ministry-item`}>
        <div>
          <div className="ministry-image background-white small-m-b-4">
            <img
              src={imageSecond}
              alt={titleSecond}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleSecond} </h2>
          <div className="ministry-desc">
          <div className="m-b-16" dangerouslySetInnerHTML={{__html: descSecond }} />
          </div>
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more bordeaux right"
           //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
      </div>
      <div className={`${breakpoints.md ? 'col-sm-2' : 'col-sm-3'}`}></div>

    </div>
  )
}

const SectionMakeDifference = (props) => {
  const {
    t,
    pos,
    imageFirst,
    titleFirst,
    descFirst,
    urlFirst,
    imageSecond,
    titleSecond,
    descSecond,
    urlSecond
  } = props

  const breakpoints = useBreakpoint()

  return(
    <div className={`row small-p-l-r-0 ${ pos === 1 ? 'm-b-80-' : ''} medium-m-b-40 small-m-0`}>
      {!breakpoints.md &&
        <div className="col-sm-2"></div>
      }

      <div className={`${breakpoints.md ? 'col-sm-6' : 'col-sm-4 col-6'} ministry-item small-m-b-24`}>
        <div>
          <div className="ministry-image background-green m-b-16 small-m-b-10">
            <img
              src={imageFirst}
              alt={titleFirst}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleFirst} </h2>
          <div className="ministry-desc m-b-31">
            <div dangerouslySetInnerHTML={{__html: descFirst }} />
          </div>
            <Button2
             title={t("Troba un ministeri")}
             className="r-more green right"
             //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
        </div>
      </div>

      <div className={`${breakpoints.md ? 'col-sm-6' : 'col-sm-4 col-6'} ministry-item`}>
        <div>
          <div className="ministry-image background-green m-b-16 small-m-b-10">
            <img
              src={imageSecond}
              alt={titleSecond}
              className="m-r--24"
            />
          </div>
        </div>
        <div>
          <h2 className="title ministry-title m-b-8"> {titleSecond} </h2>
          <div className="ministry-desc m-b-31 small-m-b-24">
          <div dangerouslySetInnerHTML={{__html: descSecond }} />
          </div>
          <Button2
           title={t("Les nostres missions")}
           className="r-more green right"
           //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
      </div>
      {!breakpoints.md &&
        <div className="col-sm-2"></div>
      }

    </div>
  )
}

export const useGroupedTeam = (team) => {

    const groupedData = team?.nodes?.reduce((acc, curr) => {
    acc[curr.field_team_type] = acc[curr.field_team_type] || [];
    acc[curr.field_team_type].push(curr);
    return acc;
  }, {});
  return groupedData
}

 export default LeadershipPage
